import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import PayWithoutLogin from '../pages/PayWithoutLogin';

const Layout = lazy(() => import('../components/Layout'));
const Login = lazy(() => import('../pages/Login'));
const PasswordRecovery = lazy(() => import('../pages/PasswordRecovery'));
const Communications = lazy(() => import('../pages/Communications'));
const SingleDetail = lazy(() =>
  import('../components/Communications/Client/SingleDetail'),
);
const ViewProduct = lazy(() => import('../pages/ProductView'));
const CreatePublication = lazy(() =>
  import('../components/Communications/Admin/CreatePublication'),
);
const EditPublication = lazy(() =>
  import('../components/Communications/Admin/EditPublication'),
);
const Support = lazy(() => import('../pages/Support'));
const DetailTicket = lazy(() => import('../components/Support/Client/DetailTicket'));
const Users = lazy(() => import('../pages/Users'));
const Products = lazy(() => import('../pages/Products'));
const Payments = lazy(() => import('../pages/Payments'));
const ManualView = lazy(() => import('../components/Manuals/ViewManual'));
const ManualsCreate = lazy(() => import('../components/Manuals/CreateManual'));
const PaymentCheck = lazy(() => import('../pages/PaymentCheck'));
const ManualsPage = lazy(() => import('../pages/Manuals'));

const routes = [
  {
    path: '/',
    key: 'INDEX',
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return <Redirect to="/pagos" />;
      }
      return <Login />;
    },
  },
  {
    path: '/realizar-pagos',
    key: 'realizar-pagos',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return <Redirect to="/realizar-pagos" />;
      }
      return <PayWithoutLogin />;
    },
  },
  {
    path: '/recuperar/:token',
    key: 'passwordRecovery',
    exact: true,
    component: PasswordRecovery,
  },
  {
    path: '/comunicaciones',
    key: 'Comns',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return <Communications />;
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/comunicaciones/crear-publicacion',
    key: 'Publication',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <CreatePublication />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/comunicaciones/editar-publicacion',
    key: 'Publication',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <EditPublication />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/comunicaciones/:idComns',
    key: 'Single',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <SingleDetail />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/soporte',
    key: 'support',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <Support />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/soporte/:ticketId',
    key: 'support_single_details',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <DetailTicket />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/usuarios',
    key: 'users',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <Users />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/productos',
    key: 'products',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <Products />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/productos/:productId',
    key: 'productDetails',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <ViewProduct />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/pagos',
    key: 'payments',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <Payments />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/manuales',
    key: 'all_manuals',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <ManualsPage />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/manuales/crear',
    key: 'manuals_create',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <ManualsCreate />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/manuales/:manualsId',
    key: 'manuals_details',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <ManualView />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/manuales/:manualsId/editar',
    key: 'manuals_details',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <ManualView isEdit />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  {
    path: '/pagos-nuevo-producto',
    key: 'newProductCheck',
    exact: true,
    component: ({ isAuthenticated }) => {
      if (isAuthenticated) {
        return (
          <Layout>
            <PaymentCheck />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    },
  },
  { path: '*', component: () => <Redirect to="/login" /> },
];

export default routes;

